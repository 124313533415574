<template>
  <div>
    <v-dialog v-model="editSeptic" max-width="900">
      <v-card class="mx-auto">
        <v-card-title
          >Edit Septic Maintenance <v-spacer></v-spacer>
          <v-btn icon @click="editSeptic = !editSeptic"
            ><v-icon>mdi-close</v-icon></v-btn
          ></v-card-title
        >
        <v-container>
          <base-form
            update
            :data="editData"
            @submit="updateSepticHistory"
            @cancel="editSeptic = false"
          >
            <template v-slot:title>
              <span></span>
            </template>
            <template v-slot:button> Update <v-icon>mdi-content-save</v-icon></template>
          </base-form>
        </v-container>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dlt.dialog" max-width="400">
      <v-card class="mx-auto">
        <v-card-title></v-card-title>
        <v-card-text> Are you sure? Do you want to delete this item? </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" @click="deleteItem()">Yes</v-btn
          ><v-btn color="secondary" text @click="dlt.dialog = false">No</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <base-data-table
      :loading="loading"
      :headers="headers"
      :items="septicDataList"
      class="elevation-1"
      dense
    >
      <template v-slot:[`item.checks`]="{ item }">
        <span>
          <v-chip small color="primary" v-if="item.pumped" class="ml-1">Pump</v-chip>
          <v-chip small color="info" v-if="item.inspected" class="ml-1">{{
            item.inspection.type == 'title_5' ? 'Title 5' : 'Inspect'
          }}</v-chip>
          <v-chip small color="error" v-if="item.repaired" class="ml-1">Repair</v-chip>
        </span>
      </template>
      <template v-slot:[`item.cost`]="{ item }">
        <span v-if="item.cost"> ${{ Intl.NumberFormat().format(item.cost) }} </span>
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <v-chip small v-if="item.inspection.further_action" color="warning">Further</v-chip>

        <v-tooltip
          v-if="typeof item.inspection.report_file == 'object' && item.inspection.report_file.url"
          bottom
          class="d-inline"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              color="primary"
              @click="newWindow(item.inspection.report_file.url)"
              ><slot name="icon">mdi-file-pdf</slot></v-icon
            >
          </template>
          <span
            ><slot name="tooltip"
              >Inspection Report: {{ item.inspection.report_file.filename }}</slot
            ></span
          >
        </v-tooltip>
        <DisplayFile :file="item.report" />
        <UpdateDelete @update="editItem(item.index)" @delete="showDeleteDialog(item.index)" />
      </template>
    </base-data-table>
  </div>
</template>

<script>
import utils from '@/mixins/utils';
import BaseForm from '@/components/Septic/BaseForm';
import parcel from '@/mixins/parcel';
import { mapGetters, mapMutations } from 'vuex';
export default {
  mixins: [utils, parcel],
  components: {
    BaseForm: BaseForm,
    UpdateDelete: () => import('@/components/Feature/UpdateDelete.vue'),
    DisplayFile: () => import('@/components/Feature/DisplayFile.vue')
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    septic_index: {
      type: Number,
      default: 0
    }
  },
  data: () => ({
    headers: [
      {
        text: 'Date',
        align: 'center',
        sortable: false,
        value: 'serviceDate',
        class: 'primary white--text'
      },
      {
        text: 'Maintenance',
        align: 'left',
        sortable: false,
        value: 'checks',
        class: 'primary white--text'
      },
      {
        text: 'Company',
        align: 'left',
        sortable: false,
        value: 'company',
        class: 'primary white--text'
      },
      {
        text: 'Next Service',
        align: 'center',
        sortable: false,
        value: 'next_service_year',
        class: 'primary white--text'
      },
      {
        text: 'Cost',
        align: 'right',
        sortable: false,
        value: 'cost',
        class: 'primary white--text'
      },
      {
        text: 'Actions',
        align: 'right',
        sortable: false,
        value: 'action',
        class: 'primary white--text',
        width: 150
      }
    ],
    edit: {
      dialog: false,
      data: null
    },
    dlt: {
      dialog: false
    }
  }),
  computed: {
    isMD() {
      return this.$vuetify.breakpoint.mdAndDown;
    },
    editSeptic: {
      get() {
        return this.$store.getters.editSeptic;
      },
      set(value) {
        this.$store.commit('setEditSeptic', value);
      }
    },
    editData() {
      let data = this.copyObject(this.$store.getters.activeSepticData || {});
      return data;
    },
    ...mapGetters(['activeSeptic', 'septicDataList', 'activeSepticData'])
  },
  methods: {
    editItem(index) {
      this.setActiveSeptic(index);
      this.edit.data = this.copyObject(this.activeSepticData);
      this.editSeptic = true;
    },
    showDeleteDialog(index) {
      this.setActiveSeptic(index);
      this.dlt.dialog = true;
    },
    deleteItem() {
      if (this.activeSeptic == -1) {
        return;
      }
      this.$store.commit('removeSepticData', {
        index: this.activeSeptic
      });
      this.$emit('updated');
      this.dlt.dialog = false;
      this.setActiveSeptic(-1);

      this.updateMetrics({ feature: 'septic', action: 'delete' });
    },
    updateSepticHistory(data) {
      if (this.activeSeptic == -1) {
        return;
      }
      this.$store.commit('updateSepticData', {
        index: this.activeSeptic,
        data: { ...data.formData }
      });
      this.editSeptic = false;
      this.$emit('updated');

      this.updateMetrics({ feature: 'septic', action: 'update' });
    },
    ...mapMutations(['setActiveSeptic'])
  }
};
</script>

<style></style>
