<template>
  <v-main>
    <v-dialog v-model="dialogs.system" max-width="1000"
      ><v-card class="mx-auto">
        <v-card-title>
          Septic System Information <v-spacer></v-spacer>
          <v-btn icon @click="closeSepticCard()"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-container class="pa-3">
          <septic-system-form
            @cancel="closeSepticCard()"
            @submit="saveSystemInformation"
            :data="septicSystemData"
          >
            <template v-slot:submit v-if="septicSystemData">
              Update <v-icon>mdi-content-save</v-icon></template
            >
          </septic-system-form>
        </v-container></v-card
      ></v-dialog
    >
    <v-dialog v-model="dialogs.firstTime" transition="dialog-bottom-transition" max-width="600">
      <peeps-hooray>
        <template v-slot:baseTitle>
          <span class="peeps--font">Awesome!</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialogs.firstTime = false"><v-icon>mdi-close</v-icon></v-btn>
        </template>
        <template v-slot:image>
          <v-img class="mx-auto" max-width="400" src="@/assets/img/PeepsCatchesFly.svg"></v-img>
        </template>
        <p>You just added your septic system to your Peeps Portal.</p>
        <p>
          And now you can click the button below to see how you will record your system
          maintainence, or come back to it when you are ready.
          <!-- or click on the Water button to record your water test results. -->
        </p>
        <div class="text-center">
          <v-btn large color="primary" class="text-capitalize" @click="dialogs.firstTime = false"
            >System Maintenance</v-btn
          >
        </div>
      </peeps-hooray>
    </v-dialog>
    <v-container>
      <feature-breadcrumb :feature="fBreadcrumb">
        <template>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <span v-on="on">
                {{ fBreadcrumb.text }}
                <septic-system-chip
                  class="ml-2"
                  v-if="component_type"
                  :componentType="component_type"
                >
                </septic-system-chip>
              </span>
            </template>
            <span>View Septic System Information </span>
          </v-tooltip>
        </template>

        <template #action>
          <base-prop-tooltip tooltip="Septic source information">
            <septic-system-chip
              @click="dialogs.system = true"
              class="ml-2"
              :componentType="component_type"
            >
            </septic-system-chip>
          </base-prop-tooltip>
          <ParcelReference :reference="parcelReference" small />
          <FeatureGalleryButton v-model="gallery" class="ml-1" icon :count="galleryCount" />
        </template>
      </feature-breadcrumb>
      <v-alert max-width="400" class="mx-auto" v-model="saved" text type="success"
        >Data saved</v-alert
      >
      <v-row>
        <v-col cols="12">
          <FeatureGallery feature="Septic" v-model="gallery" :count.sync="galleryCount" />
          <v-card elevation="3">
            <v-card-title class="primary--text cursor-pointer" @click="toggleAddDialog()"
              >Septic Maintenance
              <v-btn class="d-sm-none d-flex" icon>
                <v-icon class="primary--text">{{
                  dialogs.add ? 'mdi-chevron-up' : 'mdi-chevron-down'
                }}</v-icon></v-btn
              ></v-card-title
            >

            <v-container>
              <div class="ml-5 mr-5 mt-2 mb-2" v-show="dialogs.add">
                <septic-add-form @updated="saveData"></septic-add-form>
              </div>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
      <div class="calendar-container">
        <septic-calendar></septic-calendar>
      </div>
      <v-row>
        <v-col>
          <septic-history :loading="loading" @updated="saveData()"></septic-history>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import SepticAddForm from '@/components/Septic/AddForm';
import SepticHistory from '@/components/Septic/History';
import SepticCalendar from '../../../components/Septic/SepticCalendar.vue';
import septic from '@/mixins/septic';
import parcel from '@/mixins/parcel';
import { mapGetters } from 'vuex';
export default {
  metaInfo: {
    title: 'Septic'
  },
  components: {
    SepticAddForm,
    SepticHistory,
    SepticSystemForm: () => import('@/components/Septic/SystemForm'),
    PeepsHooray: () => import('@/components/PeepsHooray'),
    SepticCalendar,
    SepticSystemChip: () => import('@/components/Core/Chip/SepticSystemChip.vue'),
    FeatureBreadcrumb: () => import('@/components/Core/FeatureBreadcrumb.vue'),
    FeatureGallery: () => import('../../../components/Gallery/FeatureGallery.vue'),
    FeatureGalleryButton: () => import('../../../components/Gallery/FeatureGalleryButton.vue')
  },
  mixins: [septic, parcel],
  data: () => ({
    gallery: false,
    galleryCount: 0,
    dialogs: {
      system: false,
      firstTime: false,
      add: true
    },
    loading: true,
    loadings: {
      save: false,
      clear: false
    },
    saved: false,
    fBreadcrumb: {
      text: 'Septic',
      to: '?septic_card=true'
    },
    saving: false,
    DEFAULT_SEPTIC_SYSTEM: 0
  }),
  async mounted() {
    this.loading = true;
    let data = await this.getSepticData(this.$route.params.id);
    this.$store.commit('setSepticData', data);
    this.loading = false;

    this.checkSepticSystem();
    this.updateSepticCard();
  },
  computed: {
    parcelReference() {
      const ref = this.$store.state.parcel.latestParcel?.reference;
      if (ref) {
        return ref;
      }
      return '';
    },
    xs() {
      return this.$vuetify.breakpoint.xs;
    },
    component_type() {
      if (this.septicSystemData) {
        return this.getSepticComponent(this.septicSystemData.component_type);
      } else {
        return null;
      }
    },
    url() {
      return this.$route.query.septic_card;
    },
    septic_dialog() {
      return this.dialogs.system;
    },
    septicData() {
      if (Object.keys(this.$store.getters.septicData).length) {
        return this.$store.getters.septicData.septic_systems[this.DEFAULT_SEPTIC_SYSTEM];
      } else {
        return null;
      }
    },
    septicSystemData() {
      if (this.septicData) {
        return this.septicData.system_information;
      } else {
        return {};
      }
    },
    ...mapGetters(['latestParcel'])
  },
  destroyed() {
    this.$store.commit('setSepticData', {});
  },
  methods: {
    toggleAddDialog() {
      if (this.$vuetify.breakpoint.xs) {
        this.dialogs.add = !this.dialogs.add;
      }
    },
    checkSepticSystem() {
      const checked = this.$route.query.checked;
      if (checked) {
        return;
      }
      if (
        this.$store.getters.septicData &&
        typeof this.$store.getters.septicData.septic_systems == 'object' &&
        !this.$store.getters.septicData.septic_systems[0].system_information
      ) {
        let newUrl = this.$route.path + '?septic_card=true';
        if (this.$route.fullPath != newUrl) {
          this.$router.push(newUrl);
        }
      }
    },
    async saveSystemInformation(data) {
      let isFirstTime = this.septicSystemData ? false : true;
      this.$store.commit('setSepticSystemInformation', {
        data: data,
        index: this.DEFAULT_SEPTIC_SYSTEM
      });
      this.saveSepticData(this.$route.params.id, this.$store.getters.septicData);
      this.dialogs.system = false;
      if (isFirstTime) {
        setTimeout(() => {
          this.dialogs.firstTime = true;
        }, 500);
      }
    },
    async saveData() {
      await this.saveSepticData(this.$route.params.id, this.$store.getters.septicData);
    },
    showSepticCard() {
      this.dialogs.system = true;
    },
    closeSepticCard() {
      this.dialogs.system = false;
    },
    updateSepticCard() {
      if (this.url) {
        this.dialogs.system = true;
      } else {
        this.dialogs.system = false;
      }
    }
  },
  watch: {
    xs() {
      if (!this.xs) {
        this.dialogs.add = true;
      }
    },

    url() {
      this.updateSepticCard();
    },
    septic_dialog() {
      if (this.dialogs.system) {
        let newUrl = this.$route.path + '?septic_card=true';
        if (this.$route.fullPath != newUrl) {
          this.$router.push(newUrl);
        }
      } else {
        if (this.$route.fullPath != this.$route.path) {
          this.$router.push(this.$route.path + '?checked=true');
        }
      }
    }
  }
};
</script>

<style scoped>
@media screen and (min-width: 600px) {
  .address {
    margin-left: 5rem;
  }
}
@media screen and (max-width: 600px) {
  .breadcrumbs {
    justify-content: space-between !important;
  }
}
.address {
  color: rgb(89, 92, 95);
}
.septic-detail-icon {
  font-size: 25px !important;
  margin-left: 0.5rem;
}
</style>
