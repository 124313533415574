<template>
  <base-form @submit="insertSepticData" @close="$emit('close')">
    <template #title>
      <span></span>
    </template>
    <template v-slot:button>Save <v-icon>mdi-content-save</v-icon></template>
  </base-form>
</template>

<script>
import parcel from '@/mixins/parcel';
export default {
  mixins: [parcel],
  components: {
    BaseForm: () => import('./BaseForm')
  },
  props: {
    septic_system: {
      type: Number,
      default: 0
    }
  },
  methods: {
    insertSepticData(e) {
      this.$store.commit('addSepticData', {
        item: e.formData,
        index: this.septic_system
      });
      e.resetForm();
      this.$emit('updated');

      this.updateMetrics({ feature: 'septic', action: 'create' });
    }
  }
};
</script>

<style></style>
