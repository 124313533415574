import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDialog,{attrs:{"max-width":"900"},model:{value:(_vm.editSeptic),callback:function ($$v) {_vm.editSeptic=$$v},expression:"editSeptic"}},[_c(VCard,{staticClass:"mx-auto"},[_c(VCardTitle,[_vm._v("Edit Septic Maintenance "),_c(VSpacer),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){_vm.editSeptic = !_vm.editSeptic}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VContainer,[_c('base-form',{attrs:{"update":"","data":_vm.editData},on:{"submit":_vm.updateSepticHistory,"cancel":function($event){_vm.editSeptic = false}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span')]},proxy:true},{key:"button",fn:function(){return [_vm._v(" Update "),_c(VIcon,[_vm._v("mdi-content-save")])]},proxy:true}])})],1)],1)],1),_c(VDialog,{attrs:{"max-width":"400"},model:{value:(_vm.dlt.dialog),callback:function ($$v) {_vm.$set(_vm.dlt, "dialog", $$v)},expression:"dlt.dialog"}},[_c(VCard,{staticClass:"mx-auto"},[_c(VCardTitle),_c(VCardText,[_vm._v(" Are you sure? Do you want to delete this item? ")]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"error"},on:{"click":function($event){return _vm.deleteItem()}}},[_vm._v("Yes")]),_c(VBtn,{attrs:{"color":"secondary","text":""},on:{"click":function($event){_vm.dlt.dialog = false}}},[_vm._v("No")]),_c(VSpacer)],1)],1)],1),_c('base-data-table',{staticClass:"elevation-1",attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.septicDataList,"dense":""},scopedSlots:_vm._u([{key:"item.checks",fn:function(ref){
var item = ref.item;
return [_c('span',[(item.pumped)?_c(VChip,{staticClass:"ml-1",attrs:{"small":"","color":"primary"}},[_vm._v("Pump")]):_vm._e(),(item.inspected)?_c(VChip,{staticClass:"ml-1",attrs:{"small":"","color":"info"}},[_vm._v(_vm._s(item.inspection.type == 'title_5' ? 'Title 5' : 'Inspect'))]):_vm._e(),(item.repaired)?_c(VChip,{staticClass:"ml-1",attrs:{"small":"","color":"error"}},[_vm._v("Repair")]):_vm._e()],1)]}},{key:"item.cost",fn:function(ref){
var item = ref.item;
return [(item.cost)?_c('span',[_vm._v(" $"+_vm._s(Intl.NumberFormat().format(item.cost))+" ")]):_vm._e()]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(item.inspection.further_action)?_c(VChip,{attrs:{"small":"","color":"warning"}},[_vm._v("Further")]):_vm._e(),(typeof item.inspection.report_file == 'object' && item.inspection.report_file.url)?_c(VTooltip,{staticClass:"d-inline",attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({attrs:{"color":"primary"},on:{"click":function($event){return _vm.newWindow(item.inspection.report_file.url)}}},'v-icon',attrs,false),on),[_vm._t("icon",[_vm._v("mdi-file-pdf")])],2)]}}],null,true)},[_c('span',[_vm._t("tooltip",[_vm._v("Inspection Report: "+_vm._s(item.inspection.report_file.filename))])],2)]):_vm._e(),_c('DisplayFile',{attrs:{"file":item.report}}),_c('UpdateDelete',{on:{"update":function($event){return _vm.editItem(item.index)},"delete":function($event){return _vm.showDeleteDialog(item.index)}}})]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }